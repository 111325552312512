<template>
  <div>
    <p class="has-text-weight-bold is-size-4 mb-1">
      {{
        isMortgage
          ? $t("af:mortgage_application.closed.title")
          : $t("af:application.closed.title")
      }}
    </p>
    <application-template
      v-if="isMortgage"
      template="MORTGAGE_APPLICATION_IS_CLOSED"
    ></application-template>
    <application-template
      v-else
      template="APPLICATION_IS_CLOSED"
    ></application-template>
    <b-button
      v-if="isMortgage"
      id="mortgageFlowClosedButtonBackToPrelim"
      type="is-blue"
      class="mt-6"
      @click="finishMortgageApplication"
      >{{
        $t("af:mortgage_application.closed.button.back_to_preliminary")
      }}</b-button
    >
    <b-button
      v-else
      id="flowClosedButtonBackToPrelim"
      type="is-blue"
      class="mt-6"
      @click="finishApplication"
      >{{ $t("af:application.closed.button.back_to_preliminary") }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: "ApplicationFlowClosed",
  components: {
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  props: {},
  computed: {
    isMortgage() {
      return !!this.$route.meta.mortgage;
    },
  },
  methods: {
    async finishApplication() {
      try {
        await this.$store.dispatch("loanApplication/finish");
        await this.$store.dispatch("purchase/hasActivePurchase");
        await this.$router.push("/user/applications");
      } catch (e) {
        console.error(e);
      }
    },
    async finishMortgageApplication() {
      try {
        await this.$store.dispatch("mortgageApplication/finish");
        await this.$router.push("/user/mortgage-applications");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
